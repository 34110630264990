import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io5";
import logo from "../static/images/brands/comfort garmets.png";

function Footer() {
  return (
    <div className="bg-slate-100 custom-font">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 bg-slate-100 ps-5 md:ps-0 lg:gap-10">
          <div className="flex flex-col items-start justify-start md:ms-12 lg:ms-0 lg:ps-[25%] hover:bg-slate-100 group py-4">
            <Link
              to="/"
              className="flex flex-col items-start justify-start hover:bg-slate-100 group py-4"
            >
              <img src={logo} className="h-12" alt="Logo" />
            </Link>
            <div className="flex flex-col">
              <p className="text-gray-500 text-sm">
                V P Marakkar Road, Edappally North P O,
                <br /> Kochi 682024
              </p>
              <a
                href="tel:+919061230044"
                target="_self"
                className="pt-3 text-sm text-gray-500"
              >
                +91 9061230044
              </a>
              <a
                href="mailto:cgcortex@gmail.com"
                target="_self"
                className="text-gray-500 text-sm"
              >
                cgcortex@gmail.com
              </a>
            </div>
          </div>
          <div className="flex w-full justify-between md:ms-20 lg:ms-0">
            <div className="flex flex-col lg:items-center w-1/2 md:w-full md:justify-center py-4 lg:p-4">
              <div className="flex flex-col h-full justify-between md:w-1/2 lg:w-1/3 md:pt-3 lg:pt-7">
                <div className="w-full">
                  <p className="pt-2">Connect with us</p>
                </div>

                <div className="flex pt-4 cursor-pointer group">
                  <FaFacebookF
                    size={16}
                    className="text-gray-500 group-hover:text-blue-400"
                  />
                  <a
                    href="https://www.facebook.com/profile.php?id=61555977163824&mibextid=LQQJ4d"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-2 text-sm text-gray-500 group-hover:text-blue-400"
                  >
                    Facebook
                  </a>
                </div>

                <div className="flex pt-1 lg:pt-0 cursor-pointer group">
                  <IoLogoInstagram
                    size={16}
                    className="text-gray-500 group-hover:text-rose-400"
                  />
                  <a
                    href="https://www.instagram.com/cgcortex.in?igsh=emVqcmJxenU3ZGsw"
                    target="_blank"
                    rel="noopener noreferrer" 
                    className="ps-2 text-sm text-gray-500 group-hover:text-rose-400">
                    Instagram
                  </a>
                </div>

                <div className="flex pt-1 lg:pt-0 cursor-pointer group">
                  <IoLogoWhatsapp
                    size={16}
                    className="text-gray-500 group-hover:text-green-400"
                  />
                  <a
                    href="https://wa.me/9061230055"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-2 lg:pb-3 text-sm text-gray-500 group-hover:text-green-400"
                  >
                    Whatsapp
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:items-center me-4 md:hidden hover:bg-slate-100 group py-4 lg:p-4">
              <div className="text-gray-500 lg:pt-7 flex flex-col h-full justify-between">
                <div>
                  <p className="pt-2 text-black text-sm md:text-base">Menu</p>
                </div>
                <Link
                  to="/about"
                  className="cursor-pointer text-sm hover:text-blue-400 pt-4"
                >
                  About us
                </Link>
                <Link
                  to="/store"
                  className="cursor-pointer text-sm hover:text-blue-400"
                >
                  Products
                </Link>
                <Link
                  to="#terms"
                  className="cursor-pointer text-sm hover:text-blue-400"
                >
                  Terms of service
                </Link>
                <Link
                  to="#privacy"
                  className="cursor-pointer text-sm hover:text-blue-400"
                >
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:ms-20 lg:ms-0">
            <div className="flex flex-col lg:items-center lg:justify-center hover:bg-slate-100 group py-4 lg:p-4">
              <div className="text-gray-500 md:pt-3 lg:pt-7 flex flex-col h-full justify-between">
                <div>
                  <p className="pt-2 text-black text-sm md:text-base">Menu</p>
                </div>
                <Link
                  to="/about"
                  className="cursor-pointer text-sm hover:text-blue-400 md:pt-8 pt-4"
                >
                  About us
                </Link>
                <Link
                  to="/store"
                  className="cursor-pointer text-sm hover:text-blue-400 pt-2 lg:pt-0"
                >
                  Products
                </Link>
                <Link
                  to="#terms"
                  className="cursor-pointer text-sm hover:text-blue-400 pt-2 lg:pt-0"
                >
                  Terms of service
                </Link>
                <Link
                  to="#privacy"
                  className="cursor-pointer text-sm hover:text-blue-400 pt-2 lg:pt-0"
                >
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-20 bg-slate-100 py-5 flex flex-col lg:flex-row w-full justify-center items-center">
        <p className="text-xs text-gray-500">
          Copyright © 2024 Cortex. All Rights Reserved. &nbsp;
        </p>
        <a
          href="https://teqbae.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer text-xs"
        >
          Powered by <span className="text-emerald-400">Teqbae</span>{" "}
          innovations & solutions India pvt ltd
        </a>
      </div>
    </div>
  );
}

export default Footer;
