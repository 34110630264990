import React, { useState, useRef, useEffect } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";

function FilterSectionSM({ onFilterChange }) {
  const [openDrawer, setOpenDrawer] = useState(null);

  const scrollContainerRef = useRef(null);
  const [isArrowVisible, setIsArrowVisible] = useState(true);

  const toggleDrawer = (filter) => {
    setOpenDrawer(openDrawer === filter ? null : filter);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    gender: [],
    category: [],
    size: [],
    price: [],
    brand: [],
  });

  const handleClearAll = () => {
    setOpenDrawer(null);
    setSelectedFilters({
      gender: [],
      category: [],
      size: [],
      price: [],
      brand: [],
    });
    onFilterChange({
      gender: [],
      category: [],
      size: [],
      price: [],
      brand: [],
    });
  };

  const handleCheckboxChange = (filterCategory, value) => {
    const currentFilterValues = selectedFilters[filterCategory] || [];
    const newFilterValues = currentFilterValues.includes(value)
      ? currentFilterValues.filter((item) => item !== value)
      : [...currentFilterValues, value];

    const updatedFilters = {
      ...selectedFilters,
      [filterCategory]: newFilterValues,
    };
    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const handlePriceCheckboxChange = (range) => {
    const ranges = {
      "Under ₹400": [0, 400],
      "₹400 - ₹600": [400, 600],
      "Above ₹600": [600, Infinity],
    };

    const selectedRange = ranges[range];
    const currentFilterValues = selectedFilters.price || [];
    const isRangeSelected = currentFilterValues.some(
      ([min, max]) => min === selectedRange[0] && max === selectedRange[1]
    );

    const newFilterValues = isRangeSelected
      ? currentFilterValues.filter(
          ([min, max]) =>
            !(min === selectedRange[0] && max === selectedRange[1])
        )
      : [...currentFilterValues, selectedRange];

    setSelectedFilters({
      ...selectedFilters,
      price: newFilterValues,
    });
    onFilterChange({
      ...selectedFilters,
      price: newFilterValues,
    });
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 100,
        behavior: "smooth",
      });
    }
  };

  const checkScrollEnd = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setIsArrowVisible(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", checkScrollEnd);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", checkScrollEnd);
      }
    };
  }, []);

  return (
    <div>
      <div className="lg:hidden bg-white pb-4">
        <div className="flex justify-between w-full mb-4 p-2">
          <p className="text-gray-600 font-semibold">Sort by</p>
          <p className="text-gray-500 cursor-pointer" onClick={handleClearAll}>
            Clear All
          </p>
        </div>
        <div className="flex">
          <div
            ref={scrollContainerRef}
            className="overflow-x-auto w-[90%] flex whitespace-nowrap ps-2"
          >
            {["gender", "category", "size", "brand", "price"].map((filter) => (
              <div
                key={filter}
                className="flex items-center border-r border-gray-300 pr-4 mr-4 cursor-pointer"
                onClick={() => toggleDrawer(filter)}
              >
                <p className="text-gray-700 capitalize">{filter}</p>
                <div className="ml-2">
                  <RiArrowDownSLine />
                </div>
              </div>
            ))}
          </div>
          <div className="w-10 ps-2 items-center">
            <button
              className="rounded-full flex justify-center items-center h-7 w-7 bg-gray-200"
              onClick={handleScroll}
            >
              <MdKeyboardArrowRight size={20} />
            </button>
          </div>
        </div>
      </div>

      {["gender", "category", "size", "brand", "price"].map(
        (filter) =>
          openDrawer === filter && (
            <div
              key={filter}
              className="fixed inset-x-0 bottom-0 bg-white h-48 overflow-hidden shadow-lg z-50"
            >
              <div className="flex justify-between p-4 border-b">
                <p className="text-gray-700 font-medium">
                  Select {filter.charAt(0).toUpperCase() + filter.slice(1)}
                </p>
                <button
                  className="text-gray-500"
                  onClick={() => toggleDrawer(null)}
                >
                  Close
                </button>
              </div>
              <div
                className={`h-36 overflow-y-auto ${
                  filter === "size" ? "grid grid-rows-4 grid-cols-8 px-4 pt-2 pb-4" : "p-4"
                }`}
              >
                {getFilterOptions(filter).map((option) => (
                  <label
                    key={option}
                    className={`${
                      filter === "size"
                        ? "flex items-center mt-4 pr-1"
                        : "flex items-center mt-2"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500"
                      checked={
                        filter === "price"
                          ? selectedFilters.price.some(
                              ([min, max]) =>
                                min === getRange(option)[0] &&
                                max === getRange(option)[1]
                            )
                          : selectedFilters[filter].includes(
                              option.toLowerCase()
                            )
                      }
                      onChange={() =>
                        filter === "price"
                          ? handlePriceCheckboxChange(option)
                          : handleCheckboxChange(filter, option.toLowerCase())
                      }
                    />
                    <span className="ml-2 text-gray-700">{option}</span>
                  </label>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
}

const getFilterOptions = (filter) => {
  switch (filter) {
    case "gender":
      return ["Boy", "Girl", "Unisex"];
    case "size":
      return ["S", "M", "L", "XL", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"];
    case "price":
      return ["Under ₹400", "₹400 - ₹600", "Above ₹600"];
    case "category":
      return ["Shirt", "Knitted Set", "Frock"];
    case "brand":
      return ["Comfort Baby wear", "Comfort Garments", "Lyon"];
    default:
      return [];
  }
};

const getRange = (label) => {
  switch (label) {
    case "Under ₹400":
      return [0, 400];
    case "₹400 - ₹600":
      return [400, 600];
    case "Above ₹600":
      return [600, Infinity];
    default:
      return [0, Infinity];
  }
};

export default FilterSectionSM;
