import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import FilterSection from "./FilterSection";
import FilterSectionSM from "./FilterSectionSM";
import productsData from "../static/data/product.json";

function Store() {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [products] = useState(productsData);

  const [filters, setFilters] = useState({
    gender: [],
    size: [],
    category: [],
    price: [],
    brand: [],
    discount: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const filteredProducts = products.filter((product) => {
    const matchesGender =
      !filters.gender.length || filters.gender.includes(product.gender);
    const matchesSize =
      !filters.size.length ||
      product.availableSizes.some((size) => filters.size.includes(size));
    const matchesCategory =
      !filters.category.length || filters.category.includes(product.category);
    const matchesBrand =
      !filters.brand.length || filters.brand.includes(product.brand);
    const matchesPrice =
      !filters.price.length ||
      filters.price.some(
        ([min, max]) => product.price >= min && product.price <= max
      );

    return (
      matchesGender &&
      matchesSize &&
      matchesCategory &&
      matchesBrand &&
      matchesPrice
    );
  });

  return (
    <div>
      <div className="shadow-lg fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>
      <div className="flex flex-col lg:flex-row pb-10 pt-24 px-2 md:px-10">
        <div className="lg:hidden">
          <FilterSectionSM onFilterChange={handleFilterChange} />
        </div>
        <div className="hidden lg:block lg:fixed lg:top-24 lg:left-5 lg:h-full lg:w-1/5">
          <FilterSection onFilterChange={handleFilterChange} />
        </div>
        {filteredProducts.length > 0 ? (
          <div className="lg:ml-[25%] xl:ml-[20%] 2xl:ml-[14%] lg:ps-6 w-full grid grid-cols-2 xl:grid-cols-4 gap-y-3 md:gap-y-5 gap-x-2 xl:gap-x-2">
            {filteredProducts.map((product, index) => (
              <div
                key={index}
                className="relative"
                onClick={() => navigate(`/product/${product.id}`)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className="max-w-md rounded-2xl overflow-hidden hover:shadow-2xl">
                  <div className="relative h-60 lg:h-72">
                    <img
                      className="w-full h-full object-cover"
                      src={product.image}
                      alt={product.title}
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="text-sm mb-0.5">{product.title}</h3>
                    <div className="flex items-center">
                      <Rating
                        name="half-rating-read"
                        defaultValue={4.5}
                        precision={0.5}
                        readOnly
                        sx={{ fontSize: "18px" }}
                      />
                      <p className="text-sm pt-1 ps-1">({product.price * 2})</p>
                    </div>
                    <div className="flex items-center">
                      <p className="font-bold text-gray-800 custom custom-font">
                        ₹ <span>{product.price}</span>
                      </p>
                    </div>
                    <p className="text-gray-800 pt-2 text-sm uppercase font-semibold text-left">
                      Size:{" "}
                      <span className="font-normal lowercase">
                        {product.sizes && product.sizes.length > 0
                          ? product.sizes.every(
                              (size) => !isNaN(size) && size >= 16 && size <= 36
                            )
                            ? "16 to 36"
                            : product.sizes.join(", ")
                          : "Unavailable"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-10 w-full h-[400px] flex flex-col justify-center items-center">
            <p className="text-lg font-semibold text-gray-700">
              No products available for the selected filters.
            </p>
            <p className="text-gray-500 mt-2">
              Try adjusting the filter options.
            </p>
          </div>
        )}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Store;
