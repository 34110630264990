import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeProduct, clearCart } from "../Redux/cartSlice";
import { Link } from "react-router-dom";
import products from "../static/data/product.json";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const getProductDetails = (id) => {
    const product = products.find((product) => product.id === id);

    return product;
  };

  const handleRemoveProduct = (productId) => {
    dispatch(removeProduct(productId));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const calculateTotal = () => {
    return cart.reduce((total, cartItem) => {
      const productDetails = getProductDetails(cartItem.id);
      if (productDetails) {
        return total + cartItem.quantity * cartItem.price;
      }
      return total;
    }, 0);
  };

  const handleCheckOut = () => {
    const message = cart
      .map((cartItem) => {
        const productDetails = getProductDetails(cartItem.id);
        const productLink = `${window.location.origin}/product/${productDetails.id}`;
        return `*${cartItem.name}*\n ${productDetails.sNo}\nQuantity: ${cartItem.quantity}\nPrice: ₹${
          cartItem.price
        }\nTotal: ₹${cartItem.quantity * cartItem.price}\nView Product: ${productLink}\n`;
      })
      .join("\n");
  
    const total = `*Total:* ₹${calculateTotal().toFixed(2)}`;

    //console.log('cart details : ',message,' total : ',total)
  
    const whatsappMessage = encodeURIComponent(
      `Checkout Details:\n\n${message}${total}`
    );
  
    const whatsappURL = `https://wa.me/919061230055?text=${whatsappMessage}`;
    window.open(whatsappURL, "_blank");
  };  

  return (
    <div className="">
      <div className="shadow-lg fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>
      <div className="pt-10 lg:pt-24 px-5 xl:px-20 w-full" style={{ height: "67vh" }}>
        <h2 className="text-2xl font-bold mb-4">Cart</h2>

        {Array.isArray(cart) && cart.length === 0 ? (
          <p>Your cart is empty</p>
        ) : (
          <div className="overflow-auto custom-scrollbar lg:h-[77%] xl:h-[80%]">
            <table className="w-full ">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">id</th>
                  <th className="py-2 px-4 border-b">Product Name</th>
                  <th className="py-2 px-4 border-b">Image</th>
                  <th className="py-2 px-4 border-b">Quantity</th>
                  <th className="py-2 px-4 border-b">Price</th>
                  <th className="py-2 px-4 border-b">Total</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody className="text-sm lg:text-base text-center">
                {Array.isArray(cart) &&
                  cart.map((cartItem) => {
                    const productDetails = getProductDetails(cartItem.id);

                    if (!productDetails) return null;

                    // console.log(`Product ID: ${cartItem.id}`);
                    // console.log(`Product Image URL: ${productDetails.img}`);

                    return (
                      <tr key={cartItem.id}>
                        <td className="py-2 px-4 border-b">{cartItem.id}</td>
                        <td className="py-2 px-4 border-b">
                          <Link to={`/product/${productDetails.id}`} className="cursor-pointer">
                            {cartItem.name}
                          </Link>
                        </td>
                        <td className="py-2 mt-4 md:mt-0 px-4 border-b flex justify-center items-center">
                          <Link to={`/product/${productDetails.id}`}>
                            <img
                              src={productDetails.image}
                              alt={cartItem.name}
                              className="w-16 h-16"
                            />
                          </Link>
                        </td>
                        <td className="py-2 px-4 border-b">
                          {cartItem.quantity}
                        </td>
                        <td className="py-2 px-4 border-b custom-font">
                          ₹{cartItem.price}
                        </td>
                        <td className="py-2 px-4 border-b custom-font">
                          ₹{cartItem.quantity * cartItem.price}
                        </td>
                        <td className="py-2 px-4 border-b">
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-lg"
                            onClick={() => handleRemoveProduct(cartItem.id)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )} 

        <div className="flex lg:me-12 lg:ms-6 text-xs lg:text-base justify-between">
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 lg:px-4 lg:py-2 mt-4 rounded-lg"
            onClick={handleClearCart}
          >
            Clear Cart
          </button>
          <div className="flex items-center">
            {Array.isArray(cart) && cart.length > 0 && (
              <div className="mt-4 flex justify-end">
                <p className="lg:text-lg">
                  Total :{" "}
                  <span className="lg:text-xl font-semibold custom-font">
                    {" "}
                    ₹{calculateTotal().toFixed(2)}{" "}
                  </span>
                </p>
              </div>
            )}

            <button
              className="bg-green-500 hover:bg-green-600 text-white ms-3 lg:ms-5 px-2 py-2 lg:px-4 lg:py-2 mt-4 rounded-lg"
              onClick={handleCheckOut}
            >
              Check Out
            </button>
          </div>
        </div>
      </div>
      <div className="mt-10 w-full ">
        <Footer />
      </div>
    </div>
  );
};

export default Cart;
