import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FilterSection = ({ onFilterChange }) => {
  const [activeFilter, setActiveFilter] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState({
    gender: [],
    category: [],
    size: [],
    price: [],
    brand: [],
  });

  const toggleDropdown = (filter) => {
    setActiveFilter(activeFilter === filter ? null : filter);
  };

  const handleClearAll = () => {
    setActiveFilter(null);
    setSelectedFilters({
      gender: [],
      category: [],
      size: [],
      price: [],
      brand: [],
    });
    onFilterChange({
      gender: [],
      category: [],
      size: [],
      price: [],
      brand: [],
    });
  };

  const handleCheckboxChange = (filterCategory, value) => {
    const currentFilterValues = selectedFilters[filterCategory];
    const newFilterValues = currentFilterValues.includes(value)
      ? currentFilterValues.filter((item) => item !== value)
      : [...currentFilterValues, value];

    const updatedFilters = {
      ...selectedFilters,
      [filterCategory]: newFilterValues,
    };
    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const handlePriceCheckboxChange = (range) => {
    const [min, max] = range;

    const isRangeSelected = selectedFilters.price.some(
      (filterRange) => filterRange[0] === min && filterRange[1] === max
    );

    let updatedFilters;

    if (isRangeSelected) {
      updatedFilters = {
        ...selectedFilters,
        price: selectedFilters.price.filter(
          (filterRange) => !(filterRange[0] === min && filterRange[1] === max)
        ),
      };
    } else {
      updatedFilters = {
        ...selectedFilters,
        price: [...selectedFilters.price, range],
      };
    }

    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  return (
    <div>
      <div className="border rounded-2xl bg-white hidden lg:block p-4 w-full lg:w-56">
        <div className="flex justify-between mb-4 text-sm">
          <p className="text-gray-600 font-bold">Filters</p>
          <p className="text-gray-500 cursor-pointer" onClick={handleClearAll}>
            Clear All
          </p>
        </div>

        {["gender", "category", "size", "brand", "price"].map((filter) => (
          <div key={filter} className="mb-4 border-t pt-4 text-sm">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleDropdown(filter)}
            >
              <p className="text-black capitalize">{filter}</p>
              {activeFilter === filter ? (
                <FaChevronUp style={{ color: "gray" }} />
              ) : (
                <FaChevronDown style={{ color: "gray" }} />
              )}
            </div>
            {activeFilter === filter && (
              <div className="overflow-x-auto">
                <ul
                  className={`list-none pl-4 mt-2 flex ${
                    window.innerWidth < 640 ? "flex-row" : "flex-row"
                  } flex-wrap`}
                >
                  {filter === "gender" &&
                    ["Boy", "Girl", "Unisex"].map((value) => (
                      <li key={value} className="py-1 flex items-center mr-4">
                        <input
                          type="checkbox"
                          id={value}
                          className="mr-2"
                          onChange={() =>
                            handleCheckboxChange("gender", value.toLowerCase())
                          }
                          checked={selectedFilters.gender.includes(
                            value.toLowerCase()
                          )}
                        />
                        <label htmlFor={value}>{value}</label>
                      </li>
                    ))}
                  {filter === "size" &&
                    ["S", "M", "L", "XL", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"].map((value) => (
                      <li key={value} className="py-1 flex items-center mr-4">
                        <input
                          type="checkbox"
                          id={value}
                          className="mr-2"
                          onChange={() =>
                            handleCheckboxChange("size", value.toLowerCase())
                          }
                          checked={selectedFilters.size.includes(
                            value.toLowerCase()
                          )}
                        />
                        <label htmlFor={value}>{value}</label>
                      </li>
                    ))}
                  {filter === "category" &&
                    ["Shirt", "Knitted Set", "Frock"].map((value) => (
                      <li key={value} className="py-1 flex items-center mr-4">
                        <input
                          type="checkbox"
                          id={value}
                          className="mr-2"
                          onChange={() =>
                            handleCheckboxChange(
                              "category",
                              value.toLowerCase()
                            )
                          }
                          checked={selectedFilters.category.includes(
                            value.toLowerCase()
                          )}
                        />
                        <label htmlFor={value}>{value}</label>
                      </li>
                    ))}
                  {filter === "price" &&
                    [
                      { label: "Under ₹400", range: [0, 400] },
                      { label: "₹400 - ₹600", range: [400, 600] },
                      { label: "Above ₹600", range: [600, Infinity] },
                    ].map(({ label, range }) => (
                      <li key={label} className="py-1 flex items-center mr-4">
                        <input
                          type="checkbox"
                          id={label}
                          className="mr-2"
                          onChange={() => handlePriceCheckboxChange(range)}
                          checked={selectedFilters.price.some(
                            (selectedRange) =>
                              selectedRange[0] === range[0] &&
                              selectedRange[1] === range[1]
                          )}
                        />
                        <label htmlFor={label}>{label}</label>
                      </li>
                    ))}
                  {filter === "brand" &&
                    ["Comfort Baby wear", "Comfort Garments", "Lyon"].map(
                      (value) => (
                        <li key={value} className="py-1 flex items-center mr-4">
                          <input
                            type="checkbox"
                            id={value}
                            className="mr-2"
                            onChange={() =>
                              handleCheckboxChange("brand", value.toLowerCase())
                            }
                            checked={selectedFilters.brand.includes(
                              value.toLowerCase()
                            )}
                          />
                          <label htmlFor={value}>{value}</label>
                        </li>
                      )
                    )}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterSection;
