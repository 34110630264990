import './index.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import LandingPage from "./components/landingpage/LandingPage";
import Store from './components/Store/Store';
import About from './components/Aboutus/About'
import Contact from './components/Contact/Contact';
import ProductDetail from './components/ProductDetail/ProductDetail';
import Cart from './components/Cart/Cart';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/store" element={<Store />} />
          <Route path='/about' element={<About/>}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path='/cart' element={<Cart/>}/>
          <Route path='*' element={<PageNotFound />} /> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;