import React from "react";
import { Link } from "react-router-dom";

import catimg1 from "../static/images/category/1.jpg";
import catimg2 from "../static/images/category/2.jpg";
import catimg3 from "../static/images/category/3.jpg";
import catimg4 from "../static/images/category/4.jpg";
import catimg5 from "../static/images/category/5.jpg";
import catimg6 from "../static/images/category/6.jpg";
import catimg7 from "../static/images/category/7.jpg";
import catimg8 from "../static/images/category/8.jpg";
import catimg9 from "../static/images/category/9.jpg";
import catimg10 from "../static/images/category/10.jpg";
import catimg11 from "../static/images/category/Images and Video Set 2/1.jpg";
import catimg12 from "../static/images/category/Images and Video Set 2/2.jpg";
import catimg13 from "../static/images/category/Images and Video Set 2/3.jpg";
import catimg14 from "../static/images/category/Images and Video Set 2/4.jpg";

import video1 from "../static/videos/11 Reel Video.mp4";
import video2 from "../static/videos/comfort garments Kids Collection Video 2.mp4";

function MajorProducts() {

  const videoElements = document.querySelectorAll("video");

videoElements.forEach((video) => {
  video.muted = true;
  video.playsInline = true;
  video.play();
});


  return (
    <div>
      <div className="px-2 lg:w-1/2 xl:w-1/4">
        <div className="flex lg:w-full items-center">
          <div className="bg-black w-[30px] h-0.5 lg:h-[1.5px]"></div>
          <p className="text-2xl ps-2 tracking-wide font-semibold text-gray-800">
            Major Products
          </p>
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="mt-10 lg:mt-10 flex w-full h-[750px]">
          <div className="h-full flex flex-col justify-start lg:justify-start mx-2">
            <Link
              to="/product/301"
              className="h-52 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg1}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <video
              src={video1}
              controls
              autoPlay
              loop
              muted
              playsInline
              className="h-[27rem] w-[14rem] object-cover mt-4 rounded-2xl"
            />
          </div>
          <div className="h-full flex flex-col justify-start lg:justify-end mx-2">
            <Link
              to="/product/302"
              className="h-52 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg2}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/303"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg3}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/304"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg4}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
          </div>
          <div className="h-full flex flex-col justify-end lg:justify-start mx-2">
            <Link
              to="/product/305"
              className="h-52 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg5}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/306"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg6}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/307"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg7}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
          </div>
          <div className="h-full flex flex-col justify-start lg:justify-end mx-2">
            <Link
              to="/product/308"
              className="h-52 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg8}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/309"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg9}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/310"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg10}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
          </div>
          <div className="h-full flex flex-col justify-end lg:justify-start mx-2">
            <Link
              to="/product/201"
              className="h-52 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg11}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/202"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg12}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
            <Link
              to="/product/203"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg13}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
          </div>
          <div className="h-full flex flex-col ustify-start lg:justify-end mx-2 ">
            <video
              src={video2}
              controls
              autoPlay
              loop
              muted
              playsInline
              className="h-[27rem] w-[14rem] object-cover rounded-2xl"
            />
            <Link
              to="/product/204"
              className="h-52 mt-4 2xl:w-[14rem] rounded-2xl overflow-hidden"
            >
              <img
                src={catimg14}
                alt="Major products"
                className="h-full w-full object-cover rounded-2xl transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="h-[48rem] mt-10 lg:hidden">
        <div className="flex h-full justify-end ">
        <div className="hidden md:block lg:hidden">
          <div className="h-full flex flex-col justify-center lg:justify-end mx-2">
            <Link
              to="/product/308"
            >
              <img
                src={catimg8}
                alt="Major products"
                className="h-48 w-full object-cover rounded-2xl"
              />
            </Link>
            <Link
              to="/product/309"
            >
              <img
                src={catimg9}
                alt="Major products"
                className="h-48 w-full object-cover rounded-2xl"
              />
            </Link>
            <Link
              to="/product/310"
            >
              <img
                src={catimg10}
                alt="Major products"
                className="h-48 w-full object-cover rounded-2xl"
              />
            </Link>
          </div>
        </div>
          <div className="h-full flex flex-col justify-start lg:justify-end ">
            <video
              src={video1}
              controls
              autoPlay
              loop
              muted
              playsInline
              className="h-[19rem] w-[14rem] object-cover rounded-2xl"
            />
            <Link to="/product/301">
              <img
                src={catimg1}
                alt="Major products"
                className="h-48 w-full mt-4 object-cover rounded-2xl"
              />
            </Link>
            <Link to="/product/302">
              <img
                src={catimg2}
                alt="Major products"
                className="h-48 w-full object-cover mt-4 rounded-2xl"
              />
            </Link>
          </div>
          <div className="h-full flex flex-col justify-end lg:justify-start ms-4">
            <Link to="/product/303">
              <img
                src={catimg3}
                alt="Major products"
                className="h-48 w-full object-cover rounded-2xl"
              />
            </Link>
            <Link to="/product/304">
              <img
                src={catimg4}
                alt="Major products"
                className="h-48 w-full object-cover mt-4 rounded-2xl"
              />
            </Link>
            <video
              src={video2}
              controls
              autoPlay
              loop
              muted
              playsInline
              className="h-[19rem] w-[14rem] object-cover mt-4 rounded-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MajorProducts;
