import { createSlice } from '@reduxjs/toolkit';

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('cart');
    if (serializedState === null) return [];
    return JSON.parse(serializedState);
  } catch (e) {
    console.error("Could not load state", e);
    return [];
  }
};

const initialState = loadStateFromLocalStorage();

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const product = action.payload;
      const existingProduct = state.find((item) => item.id === product.id);

      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        state.push({ ...product, quantity:product.quantity });
      }

      localStorage.setItem('cart', JSON.stringify(state));
    },
    removeProduct: (state, action) => {
      const productId = action.payload;
      const index = state.findIndex((product) => product.id === productId);

      if (index !== -1) {
        state.splice(index, 1);

        localStorage.setItem('cart', JSON.stringify(state));
      }
    },
    clearCart: (state) => {
      state.length = 0;

      localStorage.setItem('cart', JSON.stringify(state));
    },
  },
});

export const { addProduct, removeProduct, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
