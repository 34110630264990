import React from "react";
import { Link } from "react-router-dom";
import About4 from "../static/images/about/Production.jpeg";
import About6 from "../static/images/about/about5.jpg";
import About7 from "../static/images/about/textile factory.jpeg";
import About8 from "../static/images/about/factory.jpeg";
import About9 from "../static/images/about/about9.jpeg";

function Intro() {

  return (
    <div className="px-2 lg:px-5 xl:px-20 py-8 ">
      <div className="flex flex-col lg:flex-row lg:justify-center w-full mt-10">
        <div className="lg:hidden px-2">
          <div className="flex items-center ps-5">
            <div className="bg-black w-[30px] h-0.5 lg:h-[1.5px]"></div>
            <p className="text-2xl ps-2 md:text-3xl font-regular font-sans tracking-wide font-semibold text-gray-800">
              What we do
            </p>
          </div>
        </div>
        <div className="lg:w-[42%] lg:me-10 xl:pe-24 lg:pt-5 rounded-2xl flex flex-col justify-center ">
          <div className="hidden lg:block lg:px-10">
            <div className="flex items-center">
              <div className="bg-black lg:w-[40px] h-0.5 lg:h-[1.5px]"></div>
              <p className="text-3xl ps-3 tracking-wide font-semibold text-gray-800">
                What we do
              </p>
            </div>
          </div>
          {/* <p className="text-gray-500 font-bold tracking-wider text-3xl pt-4">Products from India</p> */}
          {/* <div className="lg:w-1/2 w-full h-[30rem] mt-5 lg:hidden">
            <div className="flex h-1/2">
              <div className="h-full w-2/3 p-4 rounded-2xl">
                <LazyLoadImage
                  src={About4}
                  alt="About Us "
                  placeholder={
                    <Box
                      sx={{
                        bgcolor: "#f1f5f9",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        width={700}
                        height={210}
                      />
                    </Box>
                  }
                  className="h-full w-full object-cover rounded-2xl"
                />
              </div>
              <div className="h-full w-1/3 py-4 pe-4 rounded-2xl">
                <LazyLoadImage
                  src={About8}
                  alt="About Us "
                  placeholder={
                    <Box
                      sx={{
                        bgcolor: "#f1f5f9",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        width={700}
                        height={210}
                      />
                    </Box>
                  }
                  className="h-full w-full object-cover rounded-2xl"
                />
              </div>
            </div>
            <div className="flex h-1/2 ">
              <div className="w-1/3 ps-4 pb-4 ">
                <LazyLoadImage
                  src={About6}
                  alt="About Us "
                  placeholder={
                    <Box
                      sx={{
                        bgcolor: "#f1f5f9",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        width={700}
                        height={18}
                      />
                    </Box>
                  }
                  className="h-full w-full object-cover hidden md:block rounded-2xl"
                />
                <LazyLoadImage
                  src={About7}
                  alt="About Us "
                  placeholder={
                    <Box
                      sx={{
                        bgcolor: "#f1f5f9",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        width={700}
                        height={218}
                      />
                    </Box>
                  }
                  className="h-full w-full object-cover md:hidden rounded-2xl"
                />
              </div>
              <div className="w-2/3 px-4 pb-4">
                <LazyLoadImage
                  src={About7}
                  alt="About Us "
                  placeholder={
                    <Box
                      sx={{
                        bgcolor: "#f1f5f9",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        width={700}
                        height={18}
                      />
                    </Box>
                  }
                  className="h-full w-full object-cover hidden md:block rounded-2xl"
                />
                <LazyLoadImage
                  src={About6}
                  alt="About Us "
                  placeholder={
                    <Box
                      sx={{
                        bgcolor: "#f1f5f9",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        width={700}
                        height={218}
                      />
                    </Box>
                  }
                  className="h-full w-full object-cover md:hidden rounded-2xl"
                />
              </div>
            </div>
          </div> */}
          <div className="lg:w-1/2 w-full h-[30rem] mt-5 lg:hidden">
            <div className="flex h-full">
              <div className="h-full flex flex-col justify-center mx-2 ">
                <img
                  src={About4}
                  alt="About Us "
                  className="h-48 w-full mt-4 object-cover rounded-2xl hidden lg:block"
                />
              </div>
              <div className="h-full w-1/2 flex flex-col justify-start lg:justify-end mx-2">
                <img
                  src={About8}
                  alt="About Us "
                  className="h-48 w-full object-cover rounded-2xl"
                />
                <img
                  src={About6}
                  alt="About Us "
                  className="h-48 w-full object-cover mt-4 rounded-2xl"
                />
              </div>
              <div className="h-full w-1/2 flex flex-col justify-end lg:justify-start mx-2">
                <img
                  src={About7}
                  alt="About Us "
                  className="h-48 w-full object-cover rounded-2xl"
                />
                <img
                  src={About9}
                  alt="About Us "
                  className="h-48 w-full object-cover mt-4 rounded-2xl"
                />
              </div>
            </div>
          </div>
          <div className="pt-10 px-5 md:px-5 ">
            <p className="text-justify custom-font">
              Cortex New Generation ensures top-notch quality through a
              meticulous manufacturing process, blending advanced technology
              with skilled craftsmanship. Each product, from 'Comfort Garments'
              to 'Comfort Baby Wear', 'Lyon' is made with premium materials and
              thorough quality checks.
            </p>
            <div className="grid grid-cols-3 gap-y-4 gap-x-2 xl:w-3/4 mt-10 tracking-wide custom-font ">
              <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#000000"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className="ml-2">Quality</p>
              </div>
              <div className="flex items-center lg:justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#000000"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className="ml-2">Soft</p>
              </div>
              <div className="flex items-center lg:justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#000000"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className="ml-2">Durable</p>
              </div>
              <div className="flex items-center ">
              <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#000000"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className="ml-2">Comfort</p>
              </div>
              <div className="flex items-center lg:justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#000000"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className="ml-2">Style</p>
              </div>
              <div className="flex items-center lg:justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#000000"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className="ml-2">Modern</p>
              </div>
            </div>
            <div className="w-full flex justify-start pt-10 lg:pt-12 xl:pt-14">
              <Link
                to="/about"
                className="bg-black rounded-lg px-5 py-3 text-sm font-medium hover:text-black border text-white hover:bg-white"
              >
                know more
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="lg:w-1/2 h-[30rem] hidden lg:block">
          <div className="h-1/2 w-full flex">
            <div className="h-full w-2/3 py-4 ps-4">
              <img
                src={About4}
                alt="About Us "
                className="h-full w-full object-cover rounded-2xl"
              />
            </div>
            <div className="h-full w-1/3 p-4">
              <img
                src={About8}
                alt="About Us "
                className="h-full w-full object-cover rounded-2xl"
              />
            </div>
          </div>
          <div className="flex h-1/2">
            <div className="w-1/3 ps-4 pb-4">
              <img
                src={About6}
                alt="About Us "
                className="h-full w-full object-cover rounded-2xl"
              />
            </div>
            <div className="w-2/3 px-4 pb-4">
              <img
                src={About7}
                alt="About Us "
                className="h-full w-full object-cover rounded-2xl"
              />
            </div>
          </div>
        </div> */}
        <div className="lg:w-[58%] h-[30rem] hidden lg:block">
          <div className="flex h-full justify-end ">
            <div className="h-full flex flex-col justify-center mx-2 ">
              <img
                src={About4}
                alt="About Us "
                className="h-52 w-56 mt-4 object-cover rounded-2xl hidden lg:block"
              />
            </div>
            <div className="h-full flex flex-col justify-start lg:justify-end mx-2">
              <img
                src={About8}
                alt="About Us "
                className="h-52 w-56 object-cover rounded-2xl"
              />
              <img
                src={About6}
                alt="About Us "
                className="h-52 w-56 object-cover mt-4 rounded-2xl"
              />
            </div>
            <div className="h-full flex flex-col justify-end lg:justify-start mx-2">
              <img
                src={About7}
                alt="About Us "
                className="h-52 w-56 object-cover rounded-2xl"
              />
              <img
                src={About9}
                alt="About Us "
                className="h-52 w-56 object-cover mt-4 rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
