
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../Navbar/Navbar";
import Categories from "../category/Categories";
import Footer from "../Footer/Footer";
import banner1 from "../static/images/landing pg/2nd.png";
import banner3 from "../static/images/landing pg/Artboard 2.jpg";
import banner4 from "../static/images/landing pg/banner4.jpg";
import banner5 from "../static/images/landing pg/banner5.jpeg";
import banner8 from "../static/images/landing pg/banner8.jpeg";
import banner9 from "../static/images/landing pg/banner9.jpeg";
import { FaWhatsapp } from "react-icons/fa";
import Intro from "../Aboutus/Intro";
import MajorProducts from "../MajorProducts/MajorProducts";

function LandingPage() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slider1 = [banner1, banner3];
  const slider2 = [banner4, banner5];
  const slider3 = [banner8, banner9];

  return (
    <div className="relative">
      <div className="fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>

      <div className="pt-20">
        {/* SLIDER SECTION */}
        <div className="hidden lg:block">
          <div className="w-full flex rounded-xl px-4 sm:px-12 lg:px-5 overflow-hidden h-[620px] lg:h-[82vh] xl:h-[85vh] 2xl:h-[620px]">
            <div className="w-4/6 overflow-hidden rounded-2xl">
              <Slider {...settings}>
                {slider1.map((img, index) => (
                  <div
                    to="/store"
                    key={index}
                    className="w-full h-[700px] lg:h-[650px] xl:h-[780px] 2xl:h-[700px] flex-shrink-0 flex items-center justify-center rounded-xl"
                  >
                    <img
                      src={img}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-full object-cover	"
                    />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="w-2/6 flex ps-5 flex-col">
              <div className="flex-1 rounded-2xl overflow-hidden">
                <Slider {...settings}>
                  {slider2.map((img, index) => (
                    <div key={index} className="w-full h-full ">
                      <Link to="/store">
                        <img
                          src={img}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-full object-start"
                        />
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="flex-1 rounded-2xl mt-5 overflow-hidden">
                <Slider {...settings}>
                  {slider3.map((img, index) => (
                    <div key={index} className="w-full h-80 xl:h-96 2xl:h-80">
                      <Link to="/store">
                        <img
                          src={img}
                          alt={`Slide ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-2xl mt-4 md:mt-0 mx-2 lg:hidden overflow-hidden">
          <div className="relative w-full ">
            <Slider {...settings}>
              {slider1.map((img, index) => (
                <div
                  key={index}
                  className="w-full h-full flex items-center justify-center"
                >
                  <Link to="/store">
                    <img
                      src={img}
                      alt={`Product gallery ${index + 1}`}
                      className="w-full h-72 md:h-[21rem] object-cover rounded-2xl"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
          <div className="w-full flex mt-0 gap-2">
            <div className="flex-1 rounded-2xl overflow-hidden">
              <Slider {...settings}>
                {slider2.map((img, index) => (
                  <div key={index} className="w-full h-44 md:h-52">
                    <Link to="/store">
                      <img
                        src={img}
                        alt={`Slide ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="flex-1 rounded-2xl overflow-hidden">
              <Slider {...settings}>
                {slider3.map((img, index) => (
                  <div key={index} className="w-full h-44 md:h-52">
                    <Link to="/store">
                      <img
                        src={img}
                        alt={`Slide ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        {/* END OF SLIDER SECTION */}

        <div className="mt-0 lg:mt-10 2xl:mt-0 lg:pe-2">
          <Intro />
        </div>
        <div className="flex flex-col mx-5 lg:mx-10 mt-5 lg:mt-14">
          <MajorProducts />
        </div>
        {/* <div className="flex items-center">
              <div className="relative w-full">
                <div
                  className="flex overflow-x-auto lg:mx-4 custom-scrollbar gap-4"
                  ref={scrollContainerRef}
                >
                  {catimglg1.map((category, index) => (
                    <div
                      key={index}
                      className="flex-shrink-0 w-40 rounded-2xl lg:w-56 flex-col items-center h-52 lg:h-72 overflow-hidden"
                    >
                      <Link to="/store">
                        <img
                          src={category.img}
                          alt={category.title}
                          className="w-full h-52 lg:h-72 rounded-2xl object-cover transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className=" mt-5">
              <div className="flex items-center">
                <div className="relative w-full">
                  <div
                    className="flex overflow-x-auto lg:mx-4 custom-scrollbar gap-4"
                    ref={scrollContainerRef}
                  >
                    {catimglg2.map((category, index) => (
                      <div
                        key={index}
                        className="flex-shrink-0 w-40 rounded-2xl lg:w-56 flex-col items-center h-52 lg:h-72 overflow-hidden"
                      >
                        <Link to="/store">
                          <img
                            src={category.img}
                            alt={category.title}
                            className="w-full h-52 lg:h-72 rounded-2xl object-cover transition-transform duration-500 ease-in-out transform hover:scale-[1.20]"
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}

        {/* CATECORY SECTION */}
        <div className="mt-10 lg:mt-20">
          <Categories />
        </div>
        {/* END OF CATECORY SECTION */}

        {/* FOOTER SECTION */}
        <div>
          <Footer />
        </div>
        {/* END OF FOOTER SECTION */}
      </div>

      {/* WhatsApp Chat Button */}
      <a
        href="https://wa.me/9061230055"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-6 right-4 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600"
        style={{ fontSize: "24px" }}
      >
        <FaWhatsapp />
      </a>
      {/*End of WhatsApp Chat Button */}
    </div>
  );
}

export default LandingPage;
