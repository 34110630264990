import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import About1 from "../static/images/about/aboutmain4.jpeg";
import brand1 from "../static/images/brands/comfort babies.png";
import brand2 from "../static/images/brands/cortex-logo.png";
import brand3 from "../static/images/brands/logo.png";

function About() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return (
    <div>
      <div className="mx-8 md:mx-14 py-8">
        <div className="shadow-lg fixed top-0 left-0 w-full z-50">
          <Navbar />
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-center w-full pt-10 lg:pt-20">
          <div className="flex flex-col lg:flex-row lg:justify-center w-full">
            <div className="lg:hidden">
              <div className="flex items-center mt-5">
                <div className="bg-black w-[30px] lg:w-[40px] h-0.5 lg:h-px"></div>
                <p className="text-2xl ps-4 lg:text-3xl tracking-wide font-semibold text-gray-800">
                  About Us
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 mt-5 lg:mt-0 md:h-96 overflow-hidden rounded-2xl">
              <LazyLoadImage
                src={About1}
                alt="About Us"
                placeholder={
                  <Box
                    sx={{
                      bgcolor: "#f1f5f9",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      variant="rectangular"
                      width={700}
                      height={418}
                    />
                  </Box>
                }
                className="w-full"
              />
            </div>
            <div className="lg:w-1/2 lg:ps-14 lg:pr-10">
              <div className="hidden lg:block">
                <div className="flex items-center">
                  <div className="bg-black w-[30px] lg:w-[40px] h-0.5 lg:h-[1.5px]"></div>
                  <p className="text-2xl ps-3 lg:text-3xl tracking-wide font-semibold text-gray-800">
                    About Us
                  </p>
                </div>
              </div>
              <p className="pt-10 tracking-tight text-justify md:text-left md:px-0 custom-font">
                Cortex New Generation offers a unique selection of fabrics and
                apparel, featuring their exclusive products:{" "}
                <span>CG Cortex</span> for versatile fashion, 'Lyon' for
                sophisticated elegance, and 'Comfort Baby Wear' for the softest
                baby clothes. Discover quality and style with Cortex.
              </p>
              <div
                className="flex justify-between md:justify-center px-10 lg:px-0 md:ps-0 w-full pt-10"
                ref={ref}
              >
                <div className="md:w-1/3 text-center">
                  <p className="text-xl md:text-3xl font-semibold md:font-medium ">
                    {inView && (
                      <>
                        <CountUp
                          start={0}
                          end={10000}
                          duration={2}
                          separator=","
                          suffix="+"
                          className="hidden md:block"
                        />
                        <CountUp
                          start={0}
                          end={1}
                          duration={2}
                          separator=","
                          suffix="K+"
                          className="md:hidden"
                        />
                      </>
                    )}
                  </p>
                  <p className="mt-2 text-center">Users</p>
                </div>
                <div className="md:w-1/3 text-center">
                  <p className="text-xl md:text-3xl font-semibold md:font-medium">
                    {inView && (
                      <CountUp start={0} end={3} duration={2} separator="," />
                    )}
                  </p>
                  <p className="mt-2">Brands</p>
                </div>
                <div className="md:w-1/3 text-center">
                  <p className="text-xl md:text-3xl font-semibold md:font-medium">
                    {inView && (
                      <CountUp start={0} end={15} duration={2} suffix="+" />
                    )}
                  </p>
                  <p className="mt-2">Years</p>
                </div>
              </div>
              <div className="w-full flex justify-start pt-10 ">
                <Link
                  to="/contact"
                  className="bg-black px-4 py-2 border hover:bg-white hover:text-black text-white rounded-lg"
                >
                  contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 lg:mt-20 w-full flex flex-col">
          <div className="flex items-center">
              <div className="bg-black w-[30px] lg:w-[40px] h-0.5 lg:h-[1.5px]"></div>
              <p className="text-2xl ms-4 lg:ms-3 lg:text-3xl tracking-wide font-semibold text-gray-800">
                Our Brands
              </p>
          </div>
          <div className="lg:flex mt-2 lg:mt-10">
            <div className="lg:w-1/2">
              <p className="text-justify hidden lg:block custom-font">
                Cortex New Generation offers three unique brands to meet diverse
                fashion needs. CG Cortex features versatile designs that blend
                modern trends with practicality. Lyon brings sophisticated
                elegance with a timeless style for any wardrobe. For the
                youngest, Comfort Baby Wear ensures the softest, most
                comfortable clothing for babies. Discover quality and style with
                each brand from Cortex.
              </p>
              <p className="text-justify lg:text-center lg:hidden custom-font">
                Cortex New Generation offers three brands: CG Cortex for
                versatile fashion, Lyon for timeless elegance, and Comfort Baby
                Wear for soft, comfortable baby clothes. Discover quality with
                Cortex.
              </p>
            </div>
            <div className="w-full lg:w-1/2 lg:ps-14 justify-between items-center flex mt-10 lg:mt-0">
              <div className="h-10 lg:h-16">
                <img src={brand1} alt="Logo" className="h-full" />
              </div>
              <div className="h-8 mt-1 lg:h-12 items-center flex flex-col justify-center ">
                <img src={brand2} alt="Logo" className="h-full" />
              </div>
              <div className="h-10 lg:h-12">
                <img src={brand3} alt="Logo" className="h-full" />
              </div>
            </div>
          </div>
          {/* <div className="w-full lg:w-1/2 justify-between flex py-5 lg:pt-8">
            <div className="h-10 lg:h-16">
              <img src={brand1} alt="Logo" className="h-full" />
            </div>
            <div className="h-10 lg:h-16">
              <img src={brand2} alt="Logo" className="h-full" />
            </div>
            <div className="h-10 lg:h-16">
              <img src={brand3} alt="Logo" className="h-full" />
            </div>
          </div> */}
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default About;
