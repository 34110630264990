import React, { useState } from "react";
import { init, send } from "emailjs-com";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { IoLocationOutline } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
init(process.env.REACT_APP_EMAILJS_USER_ID);

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    setError(null);
    setSuccess(null);
    //console.log(formData)

    try {
      await send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData
      );
      // email.js mail id: cgcortex94@gmail.com
      setSuccess("Your message has been sent successfully!");
      setFormData({ name: '', email: '', mobile: '',message: '' });
    } catch (err) {
      setError("There was an error sending your message. Please try again.");
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="lg:h-screen lg:overflow-hidden">
      <div className="shadow-lg fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>
      <div className="container mx-auto px-4 lg:px-10 py-8 pt-24 ">
        <div className="flex flex-col justify-between lg:mx-0 xl:mx-20 lg:flex-row items-start lg:space-x-10 xl:space-x-8">
          <div className="flex flex-col mb-8 w-full lg:mb-0 lg:w-1/2">
            <div className="flex items-center ">
              <div className="bg-black w-[30px] lg:w-[40px] h-0.5 lg:h-[1.5px]	"></div>
              <h1 className="text-2xl ps-4 lg:ps-3 lg:text-3xl font-regular font-sans tracking-wide font-semibold text-gray-800">
                Get in Touch
              </h1>
            </div>
            <p className="pt-4 text-sm mb-4 hidden lg:block text-gray-600 custom-font">
              We'd love to hear from you! Whether you have questions, feedback,
              or just want to say hello, our team is here to help. Reach out to
              us through our contact form, email, or give us a call. We aim to
              respond to all inquiries within 24 hours. Let's connect and make
              great things happen together!
            </p>
            <div className="text-gray-600 custom-font">
              <div className="flex mt-5 items-center">
                <IoLocationOutline size={20} />
                <p className="ps-2">
                  V P Marakkar Road, Edappally North P O, Kochi  682024
                </p>
              </div>
              <div className="flex items-center mt-2 lg:mt-4">
                <IoCall size={20} />
                <p className="ps-2">+91 9061230044, +91 9061230055, +91 9061230066</p>
              </div>
              <div className="flex items-center mt-2 lg:mt-4">
                <IoLogoWhatsapp size={20} />
                <p className="ps-2">+91 9061230055, <span>+91 9061230066</span></p>
              </div>
              <div className="flex items-center mt-2 lg:mt-4">
                <MdOutlineEmail size={20} />
                <a 
                href='mailto:cgcortex@gmail.com'
                target="_self"
                className="ps-2">cgcortex@gmail.com</a>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:ms-20 xl:ml-0 lg:w-1/2 xl:w-1/3">
            <div className="flex items-center">
              <div className="bg-black w-[30px] lg:w-[40px] h-0.5 lg:h-[1.5px]"></div>
              <h2 className="text-2xl ps-4 lg:ps-3 lg:text-3xl font-regular font-sans tracking-wide font-semibold text-gray-800">
                Leave a Message
              </h2>
            </div>
            <p className="text-lg pt-5 mb-4 lg:hidden custom-font">
              We'd love to hear from you! Contact us via our form, email, or
              phone, and we'll respond within 24 hours. Let's connect!
            </p>
            <div className="mt-4">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Name"
                  className="p-2 border rounded-lg border-gray-300 focus:outline-none lg:placeholder:text-sm"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Email"
                  className="p-2 border rounded-lg border-gray-300 focus:outline-none lg:placeholder:text-sm"
                />
                <input
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  placeholder="Mobile"
                  pattern="[6-9][0-9]{9}"
                  title="Please enter a valid number "
                  className="p-2 border rounded-lg border-gray-300 focus:outline-none lg:placeholder:text-sm"
                />

                <textarea
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Message"
                  className="p-2 border rounded-lg border-gray-300 focus:outline-none lg:placeholder:text-sm"
                ></textarea>
                <button
                  type="submit"
                  className="bg-black border text-white py-2 px-4 hover:bg-white hover:text-black rounded-lg focus:outline-none"
                  disabled={sending}
                >
                  {sending ? "Sending..." : "Submit"}
                </button>
              </form>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            {success && <p className="text-green-500 mt-2">{success}</p>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
