import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import { IoCartOutline } from "react-icons/io5";
import { RiMenu3Fill } from "react-icons/ri";
import { RiShoppingBag4Line } from "react-icons/ri";
import logo from "../static/images/brands/comfort garmets.png";

const iconMap = {
  Home: <HomeOutlinedIcon />,
  Store: <StorefrontOutlinedIcon />,
  About: <InfoOutlinedIcon />,
  Contact: <ContactPhoneOutlinedIcon />,
  cart: <IoCartOutline size={26} />,
};

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const cartItemCount = useSelector((state) => state.cart.length);
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleNavigation = (path) => {
    if (path === "close") {
      toggleDrawer("right", false)();
    } else {
      navigate(path);
      toggleDrawer("right", false)();
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Home", "Store", "About", "Contact", "cart"].map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() =>
                handleNavigation(
                  text === "Close"
                    ? "close"
                    : text === "Home"
                    ? "/"
                    : `/${text.toLowerCase()}`
                )
              }
            >
              <ListItemIcon>{iconMap[text]}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ListItem disablePadding>
        <Link
          to="/store"
          className="border w-full mb-4 bg-black flex items-center text-sm font-regular text-white transition-custom py-1 px-4"
          //className="border mx-4 mb-4 bg-red-600 flex items-center text-sm font-regular hover:bg-orange-700 text-white transition-custom py-1 px-4"
        >
          <RiShoppingBag4Line size={40} className="pe-4" />
          <p className="ps-4 pe-1">SHOP NOW</p>
        </Link>
      </ListItem>
      <Box sx={{ flexGrow: 1 }} /> 
      <Link to="/" className="flex justify-center items-center p-4">
        <img src={logo} alt="Logo" className="h-10" />
        {/* <img src={brand1} alt="Logo" className="h-10" />
        <img src={brand2} alt="Logo" className="h-10" />
        <img src={brand3} alt="Logo" className="h-10" /> */}
      </Link>
      <div className="px-10 pe-5 pb-1 flex flex-col lg:flex-row w-full justify-center items-center">
        <a
          href="https://teqbae.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer text-xs text-center"
        >
          Powered by <span className="text-emerald-400">Teqbae</span>{" "}
          innovations & solutions India pvt ltd
        </a>
      </div>
    </Box>
  );

  return (
    <div>
      <div className="bg-stone-50 w-full px-4 md:px-14 sm:px-12 lg:px-10 py-4 flex justify-between rounded-b-lg">
        <div className="flex justify-center">
          <Link to="/" className="flex justify-center items-center">
            <img src={logo} className="h-12" />
          </Link>
        </div>
        <div className="hidden lg:block">
          <div className="flex text-sm justify-between items-center">
            <div>
              <Link
                to="/"
                className={`me-8 px-1 tracking-wide  ${
                  location.pathname === "/"
                    ? "font-medium	 text-red-600"
                    : "text-gray-600"
                } hover:text-red-600`}
              >
                Home
              </Link>
              <Link
                to="/store"
                className={`me-8 px-1 tracking-wide  ${
                  location.pathname === "/store"
                    ? "font-medium	text-red-600"
                    : "text-gray-600"
                } hover:text-red-600`}
              >
                Store
              </Link>
              <Link
                to="/about"
                className={`me-8 px-1 tracking-wide  ${
                  location.pathname === "/about"
                    ? "font-medium	text-red-600"
                    : "text-gray-600"
                } hover:text-red-600`}
              >
                About
              </Link>
              <Link
                to="/contact"
                className={`me-8 px-1 tracking-wide  ${
                  location.pathname === "/contact"
                    ? "font-medium	 text-red-600"
                    : "text-gray-600"
                } hover:text-red-600`}
              >
                Contact
              </Link>
              <Badge
                badgeContent={cartItemCount}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#E11D48",
                    color: "white",
                    width: "18px",
                    height: "18px",
                    fontSize: "10px",
                  },
                }}
              >
                <Link
                  to="/cart"
                  className={`px-1 tracking-wide  ${
                    location.pathname === "/cart"
                      ? "font-medium	 text-red-600"
                      : "text-gray-600"
                  } hover:text-red-600`}
                >
                  <IoCartOutline size={22} />
                </Link>
              </Badge>
            </div>
            <div>
              <Link
                to="/store"
                className="border ms-8 rounded-lg bg-black hover:bg-white text-white hover:text-black flex items-center justify-center text-sm font-regular transition-custom py-0.5 px-2"
              >
                <p className="ps-3 pe-1 py-2 font-medium text-xs">SHOP NOW</p>
                <RiShoppingBag4Line size={20} className="pe-1" />
              </Link>
            </div>
          </div>
        </div>
        <div className="lg:hidden">
          <div className="flex justify-center items-center">
            <Badge
              badgeContent={cartItemCount}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#E11D48",
                  color: "white",
                  width: "18px",
                  height: "18px",
                  fontSize: "10px",
                },
              }}
            >
              <Link
                to="/cart"
                className={`px-1 tracking-wide  ${
                  location.pathname === "/cart"
                    ? "font-medium	 text-red-600"
                    : "text-gray-600"
                } hover:text-red-600`}
              >
                <IoCartOutline size={22} />
              </Link>
            </Badge>
            <React.Fragment>
              <Button onClick={toggleDrawer("right", true)}>
                <RiMenu3Fill className="text-black" size={22} />
              </Button>
              <SwipeableDrawer
              
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
                onOpen={toggleDrawer("right", true)}
              >
                {list("right")}
              </SwipeableDrawer>
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
