import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const sizesData = [
  {
    size: "0-6M",
    brandSize: "0-6M",
    chest: 20,
    waist: 21,
    length: 18,
    chestCm: (20 * 2.54).toFixed(1),
    waistCm: (21 * 2.54).toFixed(1),
    lengthCm: (18 * 2.54).toFixed(1),
  },
  {
    size: "6-12M",
    brandSize: "6-12M",
    chest: 21,
    waist: 22,
    length: 19,
    chestCm: (21 * 2.54).toFixed(1),
    waistCm: (22 * 2.54).toFixed(1),
    lengthCm: (19 * 2.54).toFixed(1),
  },
  {
    size: "12-24M",
    brandSize: "12-24M",
    chest: 22,
    waist: 23,
    length: 21,
    chestCm: (22 * 2.54).toFixed(1),
    waistCm: (23 * 2.54).toFixed(1),
    lengthCm: (21 * 2.54).toFixed(1),
  },
  {
    size: "2-3Y",
    brandSize: "2-3Y",
    chest: 23,
    waist: 24,
    length: 22,
    chestCm: (23 * 2.54).toFixed(1),
    waistCm: (24 * 2.54).toFixed(1),
    lengthCm: (22 * 2.54).toFixed(1),
  },
  {
    size: "3-4Y",
    brandSize: "3-4Y",
    chest: 24,
    waist: 25,
    length: 23,
    chestCm: (24 * 2.54).toFixed(1),
    waistCm: (25 * 2.54).toFixed(1),
    lengthCm: (23 * 2.54).toFixed(1),
  },
  {
    size: "4-5Y",
    brandSize: "4-5Y",
    chest: 25,
    waist: 26,
    length: 24,
    chestCm: (25 * 2.54).toFixed(1),
    waistCm: (26 * 2.54).toFixed(1),
    lengthCm: (24 * 2.54).toFixed(1),
  },
  {
    size: "5-6Y",
    brandSize: "5-6Y",
    chest: 26,
    waist: 27,
    length: 25,
    chestCm: (26 * 2.54).toFixed(1),
    waistCm: (27 * 2.54).toFixed(1),
    lengthCm: (25 * 2.54).toFixed(1),
  },
  {
    size: "6-7Y",
    brandSize: "6-7Y",
    chest: 27,
    waist: 28,
    length: 26,
    chestCm: (27 * 2.54).toFixed(1),
    waistCm: (28 * 2.54).toFixed(1),
    lengthCm: (26 * 2.54).toFixed(1),
  },
  {
    size: "7-8Y",
    brandSize: "7-8Y",
    chest: 28,
    waist: 29,
    length: 29,
    chestCm: (28 * 2.54).toFixed(1),
    waistCm: (29 * 2.54).toFixed(1),
    lengthCm: (29 * 2.54).toFixed(1),
  },
  {
    size: "8-9Y",
    brandSize: "8-9Y",
    chest: 29,
    waist: 30,
    length: 31,
    chestCm: (29 * 2.54).toFixed(1),
    waistCm: (30 * 2.54).toFixed(1),
    lengthCm: (31 * 2.54).toFixed(1),
  },
  {
    size: "9-10Y",
    brandSize: "9-10Y",
    chest: 30,
    waist: 31,
    length: 33,
    chestCm: (30 * 2.54).toFixed(1),
    waistCm: (31 * 2.54).toFixed(1),
    lengthCm: (33 * 2.54).toFixed(1),
  },
  {
    size: "10-11Y",
    brandSize: "10-11Y",
    chest: 31,
    waist: 32,
    length: 35,
    chestCm: (31 * 2.54).toFixed(1),
    waistCm: (32 * 2.54).toFixed(1),
    lengthCm: (35 * 2.54).toFixed(1),
  },
  {
    size: "11-12Y",
    brandSize: "11-12Y",
    chest: 32,
    waist: 33,
    length: 37,
    chestCm: (32 * 2.54).toFixed(1),
    waistCm: (33 * 2.54).toFixed(1),
    lengthCm: (37 * 2.54).toFixed(1),
  },
  {
    size: "12-13Y",
    brandSize: "12-13Y",
    chest: 33,
    waist: 34,
    length: 39,
    chestCm: (33 * 2.54).toFixed(1),
    waistCm: (34 * 2.54).toFixed(1),
    lengthCm: (39 * 2.54).toFixed(1),
  },
];

export default function SizeChart({
  open,
  toggleDrawer,
  onToggle,
  productId,
  categoryId,
}) {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery("(max-width: 1245px)");
  const [isCm, setIsCm] = useState(false);

  const handleToggle = () => {
    setIsCm((prev) => !prev);
  };

  const handleClose = (e) => {
    console.log("EdtProduct - Back Button Clicked");
    e.stopPropagation();
    toggleDrawer(e);
  };

  const renderContent = () => {
    return (
      <div className="w-full h-full flex justify-center items-center bg-white">
        <div className="mx-5 h-full w-full flex flex-col justify-center items-center">
          <div className="w-full absolute left-12 lg:left-14 top-5 lg:top-5">
            <p className="text-xs lg:text-sm font-medium text-left">Size Guide</p>
          </div>
          <div className="w-full flex justify-between absolute px-10 lg:px-14 top-12 lg:top-14">
            <div>
              <p className="font-semibold text-sm lg:text-base text-black">
                Garment Measurement
              </p>
            </div>
            {/* <div className="flex items-center space-x-2">
              <span
                className={`text-sm ${
                  !isCm ? "text-blue-600" : "text-gray-500"
                }`}
              >
                in
              </span>
              <button
                onClick={handleToggle}
                className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                  isCm ? "bg-blue-600" : "bg-gray-300"
                } transition-colors duration-300`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-300 ${
                    isCm ? "translate-x-5" : "translate-x-1"
                  }`}
                />
              </button>
              <span
                className={`text-sm ${
                  isCm ? "text-blue-600" : "text-gray-500"
                }`}
              >
                cm
              </span>
            </div> */}
            <div>
              <label className="inline-flex items-center relative">
                <input
                  className="peer hidden"
                  type="checkbox"
                  onChange={handleToggle}
                />
                <div className="relative w-[60.5px] h-[20px] border border-neutral-600 bg-white rounded-full after:absolute after:content-[''] after:w-[24px] after:h-[17px] after:bg-black peer-checked:after:from-zinc-900 peer-checked:after:to-zinc-900 after:rounded-full after:top-[0.5px] after:left-[1px] active:after:w-[15px] peer-checked:after:left-[58px] peer-checked:after:translate-x-[-100%] shadow-sm duration-300 after:duration-300 after:shadow-md" />
                <p className="text-white peer-checked:text-black text-xs absolute w-6 h-6 top-0.5 left-[8px]">
                  in
                </p>
                <p className="text-black text-xs peer-checked:text-white absolute w-6 h-6 top-[1px] -right-[4px]">
                  cm
                </p>
              </label>
            </div>
          </div>
          <div className=" w-full h-[85%] lg:h-[80%] overflow-y-auto lg:w-[95%] px-4 mt-20 lg:mt-16">
            <div className="grid grid-cols-5 gap-4 sticky top-0 place-items-center bg-gray-800 text-white font-bold p-2 rounded-t-xl text-xs">
              <div className="py-2">Size</div>
              <div className="py-2">Brand Size</div>
              <div className="py-2">Chest</div>
              <div className="py-2">Waist</div>
              <div className="py-2">Length</div>
            </div>

            <div className="divide-y divide-gray-200">
              {sizesData.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-5 place-items-center gap-4 p-2 bg-white hover:bg-gray-100 text-xs"
                >
                  <div className="py-2">{item.size}</div>
                  <div className="py-2">{item.brandSize}</div>
                  <div className="py-2">{isCm ? item.chestCm : item.chest}</div>
                  <div className="py-2">{isCm ? item.waistCm : item.waist}</div>
                  <div className="py-2">
                    {isCm ? item.lengthCm : item.length}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="">
            <p className="pt-5 text-xs">
              Tip: If you don't find an exact match, Go for the next size
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={(e) => toggleDrawer(e)}
      PaperProps={{
        sx: {
          overflow: "hidden",
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <Box
        sx={{
          width: isScreenSmall ? "90vw" : 550,
          height: "100vh",
          backgroundColor: theme.palette.background.default,
          overflow: "hidden",
          position: "relative",
        }}
        role="presentation"
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 10, left: 10, color: "#000" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#000"
          >
            <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
          </svg>{" "}
        </IconButton>
        {renderContent()}
      </Box>
    </Drawer>
  );
}
