import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import college from "../static/images/category/college.jpg";
import hellostyle from "../static/images/category/style.jpg";
import Onesies from "../static/images/category/Frame 7.png";
import newStyle from "../static/images/new style.jpg";
import TrendingStyle from "../static/images/category/Frame 6.png";
import guest from "../static/images/category/Frame 9.png";
import boysTrend from "../static/images/category/Frame 10.png";
import giant from "../static/images/Giant festive bargains.png";
import tiny from "../static/images/category/Tiny.jpg";
import Just from "../static/images/just arrived.jpg";
import playtime from "../static/images/playtime.jpg";
import color from "../static/images/color.jpg";
import sparkly from "../static/images/sparkly.jpg";
import girl01 from "../static/images/baby girl 0-1.jpg";
import boy01 from "../static/images/baby boy 0-1.jpg";
import girl16 from "../static/images/lil girl 1-6.jpg";
import boy16 from "../static/images/lil boy 1-6.jpg";
import explrmore from "../static/images/category/explr more.png"
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";

const items = [
  { title: "New Style", image: newStyle },
  { title: "TrendingStyle", image: TrendingStyle },
  { title: "Trendy Collection", image: guest },
  { title: "Collections for Baby Boy", image: Onesies },
  { title: "Today Forecast:Latest Trends", image: boysTrend },
  { title: "College", image: college },
  { title: "Lightning deals", image: giant },
  { title: "tiny", image: tiny },
  { title: "hellostyle", image: hellostyle },
  { explrmore: "explore more", image: explrmore}
];

const innerBoxitem = [
  { title1: "Baby Girl", subtitle: "0-1", image: girl01 },
  { title1: "Li'l Girl", subtitle: "1-6", image: boy01 },
  { title1: "Big Girl", subtitle: "6+ Year", image: girl16 },
  { title1: "Baby Boy", subtitle: "0-1", image: boy16 },
  { title1: "Big Girl", subtitle: "6+ Year", image: girl16 },
  { title1: "Baby Boy", subtitle: "0-1", image: boy16 },
];

const innerBoxLatestitem = [
  { title1: "NEW ARRIVAL", subtitle: "New Season New finds", image: Just },
  { title1: "Maximalism", image: playtime },
  { title1: "Big Girl", subtitle: "6+ Year", image: color },
  { title1: "sparkly", subtitle: "0-1", image: sparkly },
];

const sizes = [
  { width: "100%", height: 410 },
  { width: "80%", height: 240 },
  { width: "30%", height: 270 },
  { width: "70%", height: 310 },
  { width: "90%", height: 490 },
  { width: "90%", height: 340 },
  { width: "90%", height: 740 },
  { width: "90%", height: 550 },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: 0,
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  boxShadow: "none",
  border: "none",
  borderRadius: "16px",
}));

const InnerBox = styled(Box)(({ theme, gridColumns, gridRows }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
  gridTemplateRows: `repeat(${gridRows}, 1fr)`,
  gap: theme.spacing(1),
  width: "100%",
  height: "100%",
  background: "#fff",
  border: "none",
  boxShadow: "none",
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(4, 1fr)",
  },
}));

const InnerBox2 = styled(Box)(({ theme, gridColumns, gridRows }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
  gridTemplateRows: `repeat(${gridRows}, 1fr)`,
  gap: theme.spacing(1),
  width: "100%",
  height: "100%",
  background: "#fff",
  border: "none",
  boxShadow: "none",
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
  },
}));

const NestedBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#e0e0e0",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  position: "relative",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

function Categories() {
  return (
    <div className="ps-4 lg:ps-10 lg:pe-7">
      <div className="px-2 lg:px-0 lg:w-1/2 xl:w-1/4">
        <div className="flex items-center ">
          <div className="bg-black w-[30px] h-0.5 lg:h-[1.5px]"></div>
          <p className="text-2xl ps-2 tracking-wide font-semibold text-gray-800">
            Major Category
          </p>
        </div>
      </div>
    <div className="pt-10 flex justify-center">
      
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "90%",
            md: "90%",
            lg: "100%",
          },
          minHeight: 393,
        }}
      >
        <Masonry
          columns={{
            xs: 1,
            md: 3,
          }}
          spacing={2}
        >
          {items.map((item, index) => (
            <Item
              key={index}
              className={`w-[${sizes[index]?.width}] h-[${sizes[index]?.height}] 
                sm:w-size2 sm:h-size2
                md:w-size3 md:h-size3
                lg:w-size4 lg:h-size4
                xl:w-size5 xl:h-size5
                2xl:w-size6 2xl:h-size6`}
            >
              {index === 1 ? (
                <Box sx={{ height: "auto" }}>
                  <Grid
                    container
                    spacing={{ xs: 0, md: 0, lg: 0 }} 
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gridTemplateRows: "repeat(2, 1fr)",
                      gap: { xs: 1, md: 0, lg: 1 }, 
                    }}
                  >
                    {innerBoxitem.map((innerBoxitem, index) => (
                      <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={innerBoxitem.image}
                            alt={innerBoxitem.title1}
                            sx={{ height: "100%", objectFit: "cover" }}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : index === 6 ? (
                <InnerBox2 gridColumns={2} gridRows={2}>
                  {innerBoxLatestitem.slice(0, 6).map((nestedItem, i) => (
                    <NestedBox key={i}>
                      <Link
                        to="/store"
                        style={{
                          display: "block",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img src={nestedItem.image} alt={nestedItem.title1} />
                      </Link>
                    </NestedBox>
                  ))}
                </InnerBox2>
              ) : (
                <div className="flex flex-col lg:flex-row items-center bg-stone-100 ">
                  <Link
                    to="/store"
                    style={{ display: "block", width: "100%", height: "100%" }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                </div>
              )}
            </Item>
          ))}
        </Masonry>
      </Box>
    </div>
    </div>

  );
}

export default Categories;
