import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import cartReducer from './cartSlice';

const store = configureStore({
  reducer: {
    cart: cartReducer,
  },
  devTools: {
    name: 'My Redux Store', // Custom name
    trace: true, // Enables action trace
    traceLimit: 25, // Limits the number of traces
  },
});

export default store;