import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../Redux/cartSlice";
import productsData from "../static/data/product.json";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Rating from "@mui/material/Rating";
import Footer from "../Footer/Footer";
import { LuMinus } from "react-icons/lu";
import { LuPlus } from "react-icons/lu";
import PageNotFound from "../PageNotFound";
import "../static/styles/style.css";
import SizeChart from "./SizeChart";

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow next" onClick={onClick}>
      <button className="p-2 bg-gray-900 text-white rounded-full text-7xl">
        ›
      </button>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow prev" onClick={onClick}>
      <button className="p-2 bg-gray-900 text-white rounded-full text-7xl">
        ‹
      </button>
    </div>
  );
};

function ProductDetail() {
  const { id } = useParams();

  const [quantity, setQuantity] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleIncrement = () => setQuantity(quantity + 1);
  const handleDecrement = () => quantity > 1 && setQuantity(quantity - 1);

  const handleEnquiry = () => {
    const phoneNumber = "919061230055";
    const productName = product.title;
    const productId = product.id;
    const productStyleNo = product.sNo;
    const unitPrice = product.price;
    const totalPrice = unitPrice * quantity;

    const productDetailUrl = `${window.location.origin}/product/${productId}`;

    const message = `Hello, I would like to enquire about the following product:\n\nProduct: ${productName}\n ${productStyleNo}\nQuantity: ${quantity}\nTotal Price: ₹${totalPrice.toFixed(
      2
    )}\n\nYou can view more details about the product here: ${productDetailUrl}`;

    //console.log('product details : ',message)

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  //console.log("carts : ", cart);

  const handleAddItemToCart = () => {
    //console.log("quantity : ", quantity);
    dispatch(
      addProduct({
        id: product.id,
        name: product.title,
        price: product.price,
        quantity: quantity,
      })
    );
  };

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = (e) => {
    if (e) e.stopPropagation();
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [products] = useState(productsData);

  const product = products.find((p) => p.id === Number(id));

  if (!product) {
    return (
      <div>
        <PageNotFound />
      </div>
    );
  }

  const Specifications = [
    { title: "Fabric", value: "Pure Cotton" },
    { title: "Fit", value: "Regular Fit" },
    { title: "Length", value: "Regular" },
    { title: "Occasion", value: "Casual" },
    { title: "Sleeve Length", value: "Short Sleeves" },
    { title: "Wash Care", value: "Machine Wash" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      <div className="shadow-lg fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>
      <div className="pt-24 px-2 lg:px-10 bg-white">
        <div className="flex flex-col lg:flex-row lg:gap-4 gap-2 flex-1 h-1/2">
          {/* Gallery Section */}
          <div className="flex flex-col lg:w-96 ">
            <h1 className="text-3xl mt-5 hidden font-bold mb-2 text-zinc-700">
              {product.title}
            </h1>
            <div className="relative w-full md:h-1/2">
              {/* <Slider {...settings}>
                {galleryImages.map((img, index) => (
                  <div key={index} className="w-full h-96 md:h-full">
                    <img
                      src={img}
                      alt={`Product gallery ${index + 1}`}
                      className="w-full md:h-auto object-cover rounded"
                    />
                  </div>
                ))}
              </Slider> */}
              <div className="w-full h-full md:h-full">
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-auto object-cover rounded-2xl"
                />
              </div>
            </div>
          </div>
          {/*End of Gallery Section */}

          {/* Product Details Section */}
          <div className="flex-1 lg:w-1/2 rounded px-2 lg:px-6">
            <p className=" font-medium text-zinc-700">{product.brand}</p>
            <h1
              className="text-xl mb-2 text-zinc-700"
              style={{ fontWeight: 600 }}
            >
              {product.title}
            </h1>
            <div>
              <div className=" mb-2">
                <div className="flex items-center">
                  <Rating
                    name="half-rating-read"
                    defaultValue={4.5}
                    precision={0.5}
                    readOnly
                  />
                  <p className="text-sm pt-1 ps-1">
                    ( <span> {product.price * 2}</span> )
                  </p>
                </div>
                <p className="md:text-lg text-zinc-700 font-bold md:pe-2 custom-font">
                  ₹{product.price}
                </p>
                <p>{product.sNo}</p>
              </div>
            </div>
            <div className="mt-6">
              <p className="font-semibold text-zinc-700">Product Details</p>
              <p className="text-base mb-4 tracking-wide md:pe-24 custom-font">
                {product.description}
              </p>
            </div>
            <div className="mt-5">
              <p className="font-bold text-zinc-700 mb-2">Available Size:</p>
              <div className="flex w-1/2 mb-5">
                <div className="grid grid-cols-3 uppercase text-xl gap-2 ">
                  {product.sizes.length >= 4 ? (
                    <div className="flex items-center w-[22rem] lg:w-96">
                      <Chip
                        key={0}
                        label={product.sizes[0]}
                        sx={{
                          backgroundColor: product.availableSizes.includes(
                            product.sizes[0]
                          )
                            ? "white"
                            : "white",
                          color: product.availableSizes.includes(
                            product.sizes[0]
                          )
                            ? "#1f2937"
                            : "#d1d5db",
                          border: product.availableSizes.includes(
                            product.sizes[0]
                          )
                            ? "1px solid black"
                            : "1px solid gray",
                          padding: "8px 0px",
                          // margin: "4px",
                          flex: "0 1 calc(33.333% - 8px)",
                          fontSize: "16px",
                          width: "80px",
                        }}
                      />

                      <span className="flex w-8 normal-case text-sm items-center justify-center text-zinc-700">
                        to
                      </span>

                      <Chip
                        key={product.sizes.length - 1}
                        label={product.sizes[product.sizes.length - 1]}
                        sx={{
                          backgroundColor: product.availableSizes.includes(
                            product.sizes[product.sizes.length - 1]
                          )
                            ? "white"
                            : "white",
                          color: product.availableSizes.includes(
                            product.sizes[product.sizes.length - 1]
                          )
                            ? "#1f2937"
                            : "#d1d5db",
                          border: product.availableSizes.includes(
                            product.sizes[product.sizes.length - 1]
                          )
                            ? "1px solid black"
                            : "1px solid gray",
                          padding: "8px 0px",
                          margin: "4px",
                          flex: "0 1 calc(33.333% - 8px)",
                          fontSize: "16px",
                          width: "80px",
                        }}
                      />
                      <div className="items-end ms-5 lg:ms-5 lg:me-8 hidden md:block">
                        <button
                          type="submit"
                          onClick={openDrawer}
                          className="w-24 lg:w-[6.5rem] rounded-lg flex px-2 lg:px-2 py-1.5 lg:py-2 justify-center items-center text-xs lg:text-sm mac:p-3 bg-black text-white font-semibold hover:bg-neutral-900 transition-colors"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="16px"
                            fill="#FFFFFF"
                          >
                            <path d="M120-160q-17 0-28.5-11.5T80-200q0-10 4-18.5T96-232l344-258v-70q0-17 12-28.5t29-11.5q25 0 42-18t17-43q0-25-17.5-42T480-720q-25 0-42.5 17.5T420-660h-80q0-58 41-99t99-41q58 0 99 40.5t41 98.5q0 47-27.5 84T520-526v36l344 258q8 5 12 13.5t4 18.5q0 17-11.5 28.5T840-160H120Zm120-80h480L480-420 240-240Z" />
                          </svg>
                          <span className="ps-1">Size chart</span>
                        </button>
                        {isDrawerOpen && (
                          <SizeChart
                            open={isDrawerOpen}
                            toggleDrawer={closeDrawer}
                            onToggle={(value) => console.log("Switched to:", value ? "cm" : "in")}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      {product.sizes.map((size, sizeIndex) => (
                        <div className="w-24 me-4" key={sizeIndex}>
                          <Chip
                            label={size}
                            sx={{
                              backgroundColor: product.availableSizes.includes(
                                size
                              )
                                ? "white"
                                : "white",
                              color: product.availableSizes.includes(size)
                                ? "#1f2937"
                                : "#d1d5db",
                              border: product.availableSizes.includes(size)
                                ? "1px solid black"
                                : "1px solid gray",
                              padding: "8px 0px",
                              // margin: "4px",
                              flex: "0 1 calc(33.333% - 8px)",
                              fontSize: "16px",
                              width: "100px",
                            }}
                          />
                        </div>
                      ))}

                      {/* Button after the last chip */}
                      <div className="items-end ms-5 hidden md:block ">
                        <button
                          type="submit"
                          onClick={openDrawer}
                          className="w-24 lg:w-36 rounded-lg flex px-2 lg:px-4 py-1.5 lg:py-2 justify-center items-center text-xs lg:text-sm mac:p-3 bg-black text-white font-semibold hover:bg-neutral-900 transition-colors"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="16px"
                            fill="#FFFFFF"
                          >
                            <path d="M120-160q-17 0-28.5-11.5T80-200q0-10 4-18.5T96-232l344-258v-70q0-17 12-28.5t29-11.5q25 0 42-18t17-43q0-25-17.5-42T480-720q-25 0-42.5 17.5T420-660h-80q0-58 41-99t99-41q58 0 99 40.5t41 98.5q0 47-27.5 84T520-526v36l344 258q8 5 12 13.5t4 18.5q0 17-11.5 28.5T840-160H120Zm120-80h480L480-420 240-240Z" />
                          </svg>
                          <span className="ps-1">Size chart</span>
                        </button>
                        {isDrawerOpen && (
                          <SizeChart
                            open={isDrawerOpen}
                            toggleDrawer={closeDrawer}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="md:hidden">
                <button
                  type="submit"
                  onClick={openDrawer}
                  className="w-full rounded-lg flex px-2 lg:px-4 py-1.5 lg:py-2 justify-center items-center text-xs lg:text-sm mac:p-3 bg-black text-white font-semibold hover:bg-neutral-900 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="16px"
                    fill="#FFFFFF"
                  >
                    <path d="M120-160q-17 0-28.5-11.5T80-200q0-10 4-18.5T96-232l344-258v-70q0-17 12-28.5t29-11.5q25 0 42-18t17-43q0-25-17.5-42T480-720q-25 0-42.5 17.5T420-660h-80q0-58 41-99t99-41q58 0 99 40.5t41 98.5q0 47-27.5 84T520-526v36l344 258q8 5 12 13.5t4 18.5q0 17-11.5 28.5T840-160H120Zm120-80h480L480-420 240-240Z" />
                  </svg>
                  <span className="ps-1">Size chart</span>
                </button>
                {isDrawerOpen && (
                  <SizeChart open={isDrawerOpen} toggleDrawer={closeDrawer} />
                )}
              </div>
            </div>

            <div className="mt-5 lg:mt-0">
              <div className="flex justify-between items-center w-[21.5rem] md:w-[350px] lg:w-[350px] mb-4">
                {/* flex-col md:flex-row */}
                <div className="flex items-center ">
                  <button
                    onClick={handleDecrement}
                    className="p-2 h-10 w-10 rounded flex justify-center items-center border border-gray-400 hover:border-black hover:bg-black hover:text-white text-black"
                  >
                    <p className="text-xl">
                      <LuMinus size={13} />
                    </p>
                  </button>
                  <div className="px-4 py-1.5 flex justify-center bg-white">
                    <p className="text-[18px]">{quantity}</p>
                  </div>
                  <button
                    onClick={handleIncrement}
                    className="p-2 rounded h-10 w-10 flex justify-center items-center border border-gray-400 hover:border-black hover:bg-black hover:text-white text-black"
                  >
                    <p className="font-bold">
                      <LuPlus size={13} />
                    </p>
                  </button>
                </div>
                <div className="flex md:mt-0">
                  <div className="ms-5 h-10 lg:h-10">
                    <Button
                      variant="contained"
                      onClick={handleEnquiry}
                      sx={{
                        backgroundColor: "#f87171",
                        borderRadius: "8px",
                        fontSize: "13px",
                        fontWeight: "bold",
                        // width: "120px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#dc2626",
                        },
                      }}
                    >
                      Enquiry
                    </Button>
                  </div>

                  <div className="ms-2 lg:h-10">
                    <Button
                      variant="contained"
                      onClick={handleAddItemToCart}
                      sx={{
                        backgroundColor: "#4ade80",
                        borderRadius: "8px",
                        fontSize: "13px",
                        fontWeight: "bold",
                        //width: "120px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#16a34a",
                        },
                      }}
                    >
                      Pick Item
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <p className="font-bold text-zinc-700 ps-2 lg:ps-0">
            Similar Products
          </p>
          <div className="overflow-x-auto custom-scrollbar flex space-x-2 lg:ps-6 py-4">
            {products
              .filter(
                (prod) =>
                  prod.category === product.category && prod.id !== product.id
              )
              .map((similarProduct) => (
                <div
                  key={similarProduct.id}
                  className="flex-shrink-0 rounded-2xl w-60 h-[23rem] hover:shadow-xl"
                >
                  <Link to={`/product/${similarProduct.id}`}>
                    <img
                      src={similarProduct.image}
                      alt={similarProduct.title}
                      className="w-full h-60 object-cover rounded-2xl"
                    />
                    <p className="text-zinc-700 text-sm mt-4 ps-4">
                      {similarProduct.title}
                    </p>
                    <div className="flex items-center ps-4">
                      <Rating
                        name="half-rating-read"
                        defaultValue={4.5}
                        precision={0.5}
                        readOnly
                        sx={{ fontSize: "18px" }}
                      />
                      <p className="text-sm pt-1 ps-1">
                        ( <span> {product.price * 2}</span> )
                      </p>
                    </div>
                    <div className="mt-1 ps-4">
                      <p className="text-zinc-500 custom-font">
                        ₹{similarProduct.price}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-800 pt-2 text-sm font-semibold text-left ps-4">
                        Size:{" "}
                        <span className="font-normal lowercase">
                          {product.sizes && product.sizes.length > 0
                            ? product.sizes.every(
                                (size) =>
                                  !isNaN(size) && size >= 16 && size <= 36
                              )
                              ? "16 to 36"
                              : product.sizes.join(", ")
                            : "Unavailable"}
                        </span>
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default React.memo(ProductDetail);
